import { auth } from '../config/firebase-config';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// export const createStripeAccountSession = async (account: any) => {
//     try {
//       const user = auth.currentUser;
//       if (!user) return {};
  
//       const idToken = await user.getIdToken();
//       const response = await fetch(`${API_BASE_URL}/api/stripe/create_account_session`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${idToken}`,
//         },
//         body: JSON.stringify({ account }),
//       });
  
//       if (!response.ok) {
//         throw new Error(`Failed to create Stripe account session: ${response.statusText}`);
//       }
  
//       const data = await response.json();
//       return data;
//     } catch (error) {
//       console.error('Error creating Stripe account session:', error);
//       throw error;
//     }
// };

export const checkStripeAccount = async (accountId?: string) => {
  try {
    const user = auth.currentUser;
    if (!user) return {};

    const idToken = await user.getIdToken();
    const response = await fetch(`${API_BASE_URL}/api/payments/check-stripe-account`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ accountId }), // Send accountId if available
    });

    if (!response.ok) {
      throw new Error(`Failed to check Stripe account: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error checking Stripe account:', error);
    throw error;
  }
};

export const createOrUpdateAccount = async (ownerId: string) => {
    try {
      const user = auth.currentUser;
      if (!user) return {};
  
      const idToken = await user.getIdToken();
      const response = await fetch(`${API_BASE_URL}/api/payments/create-account`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({ ownerId }),
      });
  
      if (!response.ok) {
        throw new Error(`Failed to create Stripe account: ${response.statusText}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error creating Stripe account:', error);
      throw error;
    }
};

export const createLoginLink = async (accountId: string, isStripeAccountComplete: boolean) => {
  try {
    const user = auth.currentUser;
    if (!user) return {};

    const idToken = await user.getIdToken();
    const response = await fetch(`${API_BASE_URL}/api/payments/create-login-link`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ accountId,isStripeAccountComplete }),  
    });

    if (!response.ok) {
      throw new Error(`Failed to create login link: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error creating Stripe account:', error);
    throw error;
  }
};

export const createPaymentIntent = async (amount: number, currency:string) => {
  try {
    const user = auth.currentUser;
    if (!user) return {};

    const idToken = await user.getIdToken();
    const response = await fetch(`${API_BASE_URL}/api/payments/create-payment-intent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ amount,currency }),  
    });

    if (!response.ok) {
      throw new Error(`Failed to create payment intent: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error creating Stripe payment intent:', error);
    throw error;
  }
};
  
// export const createPaymentRedirect = async (chat_id: number, lease_id: string, address: string, start_date:string, end_date:string, price: string, price_unit: string, num_beds: number) => {
//   try {
//     const user = auth.currentUser;
//     if (!user) return {};

//     const idToken = await user.getIdToken();
//     const response = await fetch(`${API_BASE_URL}/api/payments/create-checkout-session`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${idToken}`,
//       },
//       body: JSON.stringify({ chat_id, currency: 'usd', lease_id, address, start_date ,end_date ,price ,price_unit ,num_beds }),
//     });

//     if (!response.ok) {
//       throw new Error('Failed to create checkout session');
//     }

//     const data = await response.json();
//     return data;
//    // Redirect to Stripe checkout
//   } catch (error) {
//     console.error('Error during payment redirect:', error);
//     alert('Failed to initiate payment. Please try again.');
//   }
// };
export const createPaymentRedirect = async (offer:any) => {
  try {
    const user = auth.currentUser;
    if (!user) return {};

    const idToken = await user.getIdToken();
    const response = await fetch(`${API_BASE_URL}/api/payments/create-subscription-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ offer }),
    });

    if (!response.ok) {
      throw new Error('Failed to create checkout session');
    }

    const data = await response.json();
    return data;
   // Redirect to Stripe checkout
  } catch (error) {
    console.error('Error during payment redirect:', error);
    alert('Failed to initiate payment. Please try again.');
  }
};