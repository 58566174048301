import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import theme from '../../theme/theme';

interface BannerProps {
  onVisibilityChange: (visible: boolean) => void;
}

const Banner: React.FC<BannerProps> = ({ onVisibilityChange }) => {
  const [visible, setVisible] = useState(true);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile screens

  useEffect(() => {
    onVisibilityChange(visible);

    const timer = setTimeout(() => {
      setVisible(false);
      onVisibilityChange(false);
    }, 100000); // 10 seconds auto-hide

    return () => clearTimeout(timer);
  }, [visible, onVisibilityChange]);

  if (!visible) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: isMobile ? 0 : 'auto', // Move to bottom on mobile
        top: isMobile ? 'auto' : 0, // Remove top positioning on mobile
        left: 0,
        right: 0,
        backgroundColor: 'rgba(255, 255, 255, 1)', // Transparent white
        border: `2px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        padding: '15px 20px',
        borderRadius: isMobile ? '8px 8px 0 0' : '8px', // Rounded corners
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
        transition: 'bottom 0.3s ease, top 0.3s ease', // Smooth transition
      }}
    >
      <Typography
        variant="body1"
        sx={{
          fontSize: '16px',
          lineHeight: '1.5',
          fontWeight: '500',
          textAlign: 'center',
        }}
      >
        We're Beta Testing! Dublease simplifies subleasing. List your place,
        make offers, and chat with other college students.
      </Typography>
      <IconButton
        onClick={() => setVisible(false)}
        sx={{
          position: 'absolute',
          top: isMobile ? 'unset' : '5px', // Remove top positioning on mobile
          bottom: isMobile ? '5px' : 'unset', // Add bottom positioning on mobile
          right: '5px',
          color: theme.palette.primary.main,
        }}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export default Banner;
