import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, IconButton, Skeleton } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import CloseIcon from '@mui/icons-material/Close';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../config/firebase-config';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  getNotifications,
  markNotificationSeen,
} from '../../api/notificationService';
import BidCard from '../bids/BidCard';
import { brand } from '../../theme/theme';

function InboxModal() {
  const [user] = useAuthState(auth);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const onClose = () => navigate('/');

  const goToLease = (lease_id: string, chat_id: string) => {
    if (!lease_id) return;
    navigate(`/posts/${lease_id}/chat/${chat_id}`);
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      const minimumLoadingTime = 500; // Minimum loading time in ms (.5 second)
      const startTime = Date.now();
      setLoading(true);
      try {
        const data = await getNotifications();
        setNotifications(data);
      } catch (error) {
        console.log('Error:', error);
      } finally {
        const elapsedTime = Date.now() - startTime;
        const remainingTime = minimumLoadingTime - elapsedTime;
        setTimeout(() => setLoading(false), Math.max(remainingTime, 0));
      }
    };
    if (user) {
      fetchNotifications();
    }
    // Fetch data for notifications
  }, [user]);

  const renderSkeletons = () => (
    // Show Skeleton Loader when loading
    <Box
      sx={{
        maxHeight: '60vh', // Limit the height of the skeleton section
        overflowY: 'auto', // Enable scrolling for the skeleton
      }}
    >
      {[...Array(3)].map((_, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: 2,
            mb: 1,
            borderRadius: '8px',
            border: '1px solid',
            borderColor: 'divider',
          }}
        >
          {/* Skeleton for Avatar */}
          <Skeleton variant="circular" width={56} height={56} sx={{ mr: 2 }} />
          {/* Skeleton for Bid Info */}
          <Box sx={{ flexGrow: 1 }}>
            <Skeleton variant="text" width="50%" height={20} />
            <Skeleton variant="text" width="80%" height={20} sx={{ mt: 1 }} />
            <Skeleton variant="text" width="30%" height={20} sx={{ mt: 1 }} />
          </Box>
        </Box>
      ))}
    </Box>
  );

  const onClick = async (data: any) => {
    try {
      await markNotificationSeen(data.id);
      goToLease(data?.lease_id || '', data?.chat_id || '');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal open onClose={onClose} aria-labelledby="lease-details-title">
        <Box
          sx={{
            borderRadius: '10px',
            border: '1px solid',
            borderColor: 'divider',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '100%', sm: '80%', md: '70%', lg: '50%' }, // Responsive width
            maxHeight: { xs: '100vh', sm: '93vh' },
            overflowY: 'scroll', // Allow vertical scrolling
            bgcolor: 'background.paper',
            boxShadow: `0 0 100px 10px rgb(156, 204, 252)`, // Adds a blue glow
            p: { xs: 2, sm: 4 },
            pt: { xs: 8 },
            pb: { xs: 7 },
            outline: 0, // Remove default focus outline
          }}
        >
          {/* X Close Button */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mt: -2,
              mb: '2px',
            }}
          >
            <IconButton sx={{ zIndex: 1 }} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography
            variant="h5"
            component="h2"
            sx={{
              display: 'flex',
              gap: 1.2,
              color: brand[400],
              alignItems: 'center',
              justifyContent: 'center',
              mb: 5,
            }}
          >
            <MailIcon sx={{ fontSize: 32 }} />
            Inbox
          </Typography>
          {loading ? (
            renderSkeletons()
          ) : (
            // Show content when not loading
            <Box
              sx={{
                maxHeight: '60vh', // Limit the height of the cards section
                overflowY: 'auto', // Enable scrolling for the cards
              }}
            >
              {notifications.length > 0 ? (
                notifications.map((data, index) => (
                  <BidCard
                    key={data?.id || index}
                    bidderName={data?.first_name || 'dublease'}
                    bidderProfilePic={
                      data?.profile_picture_url
                        ? data.profile_picture_url
                        : data.interaction_type === 'notification'
                        ? '/Dublease_Logo.png'
                        : ''
                    }
                    seen={data.seen || false}
                    verified={data?.verified || false}
                    lastMessage={data?.notification || ''}
                    label={data?.notification_type || 'notification'}
                    timestamp={data?.timestamp || ''}
                    onClick={() => onClick(data)}
                  />
                ))
              ) : (
                <Typography variant="body1" color="textSecondary">
                  {user ? 'No notifications yet' : 'Sign in required'}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Modal>
      <Outlet />
    </>
  );
}

export default InboxModal;
