import React, { useState } from 'react';
import { Grid, Button, CircularProgress } from '@mui/material';
import ConfirmationDialog from '../../common/confirmationDialog/ConfirmationDeleteDialog';

interface ProfileActionsProps {
  isSubmitting: boolean;
  isDeleting: boolean;
  // handleSave: () => void;
  handleDelete: () => void;
  handleClose: () => void;
}

const ProfileActions: React.FC<ProfileActionsProps> = ({
  isSubmitting,
  isDeleting,
  // handleSave,
  handleDelete,
  handleClose,
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  return (
    <>
      <Grid
        container
        spacing={2}
        display="flex"
        justifyContent="space-between"
        mt="5px"
        mb="5px"
      >
        <Grid item xs={6}>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={handleClose}
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            // onClick={handleSave}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </Grid>
      </Grid>
      <Grid container display="flex" justifyContent="flex-start">
        <Button
          type="button"
          variant="text"
          sx={{ color: 'gray' }}
          onClick={() => setDeleteDialogOpen(true)}
          disabled={isSubmitting}
        >
          {isDeleting ? <CircularProgress size={24} /> : 'Delete My Account'}
        </Button>
      </Grid>
      <ConfirmationDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleDelete}
        title="Deleting your account will also delete your profile, posts, chats, and offers. Do you wish to proceed?"
        confirmText="Delete"
        cancelText="Cancel"
      />
    </>
  );
};

export default ProfileActions;
