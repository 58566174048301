import { auth } from '../config/firebase-config';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


// ------------------- Founder Notifications -------------------
export const contactDublease = async (name: string, email: string, phone_number:string, message:string) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/notifications/contactDublease`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', 
      },
      body: JSON.stringify({
        name,
        email,
        phone_number,
        message,
      }),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch user profile: ${response.statusText}`);
    }
    // Parse the server response
    await response.json();
    // Return a success message
    return { success: true, message: "Message Sent, We will follow up shortly." };
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};

export const sendSignInUpEmail = async (uid: string, email: string, displayName: string, photoURL: string, createdAt: string, currentTime: string, newUser:boolean) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/sign-in-up-analytics`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({uid, email, displayName, photoURL, createdAt, currentTime, newUser}),
    });

    if (!response.ok) {
      throw new Error('Failed to send email');
    }

    console.log('Email sent successfully');
  } catch (error) {
    console.error('Error sending email:', error);
  }
};

// ------------------- User Notifications -------------------
export const getNotifications = async () => {
  try {
    const user = auth.currentUser;

    if(!user) return {};

    const idToken = await user.getIdToken();
    const response = await fetch(`${API_BASE_URL}/api/notifications`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch user profile: ${response.statusText}`);
    }

    const data = await response.json();
    return data.notifications || [];
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};

export const getNotificationCount = async () => {
  try {
    const user = auth.currentUser;

    if (!user)  return {};

    const idToken = await user.getIdToken();

    const response = await fetch(`${API_BASE_URL}/api/notifications/getNotificationCount`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch unseen notifications: ${response.statusText}`);
    }

    const data = await response.json();
    return data.notifications || 0;
  } catch (error) {
    console.error('Error fetching unseen notifications:', error);
    throw error;
  }
};

export const markNotificationSeen = async (notification_id: string) =>{
  try {
    const user = auth.currentUser;

    if (!user)  return {};

    const idToken = await user.getIdToken();

    const response = await fetch(`${API_BASE_URL}/api/notifications/${notification_id}/markSeen`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to mark notification as seen: ${response.statusText}`);
    }
  } catch (error) {
    console.error('Failed to mark notification as seen:', error);
    throw error;
  }
};