import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  TextField,
  CircularProgress,
  Grid,
} from '@mui/material';
import PlacesAutocomplete from 'react-places-autocomplete';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

interface DistanceCalculatorProps {
  destination: string;
}

const DistanceCalculator: React.FC<DistanceCalculatorProps> = ({
  destination,
}) => {
  const [origin, setOrigin] = useState('');
  const [loading, setLoading] = useState(false);
  const [distances, setDistances] = useState<{
    [mode: string]: { distance: string; duration: string };
  }>({});

  // Handle address selection
  const handleSelect = async (address: string) => {
    try {
      setOrigin(address); // Set selected address
    } catch (error) {
      console.error('Error selecting address:', error);
    }
  };

  // Calculate distances
  const calculateDistances = async () => {
    if (!origin) {
      alert('Please enter a valid origin address.');
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/distance?origins=${encodeURIComponent(
          origin
        )}&destinations=${encodeURIComponent(destination)}`
      );
      const data = await response.json();
      if (data && data.travelModes) {
        setDistances(data.travelModes);
      } else {
        alert('Unable to calculate distance. Please try again.');
      }
    } catch (error) {
      console.error('Error fetching distance:', error);
      alert('An error occurred while calculating distances. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="subtitle1" sx={{ color: 'primary.main' }}>
        Calculate My Commute
      </Typography>

      {/* PlacesAutocomplete Input */}
      <PlacesAutocomplete
        value={origin}
        onChange={setOrigin}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <TextField
              {...getInputProps({
                label: 'Enter Location',
                fullWidth: true,
                margin: 'normal',
                required: true,
              })}
            />
            <div
              style={{
                position: 'absolute',
                zIndex: 1000,
                background: '#fff',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              }}
            >
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, index) => {
                const style = suggestion.active
                  ? {
                      backgroundColor: '#e0f7fa',
                      cursor: 'pointer',
                      padding: '8px',
                    }
                  : {
                      backgroundColor: '#ffffff',
                      cursor: 'pointer',
                      padding: '8px',
                    };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, { style })}
                    key={suggestion.placeId || index} // Ensure unique key for each item
                  >
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>

      <Grid
        container
        spacing={2}
        display="flex"
        flexDirection="row"
        mt={1}
        pl={2}
      >
        {/* Calculate Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={calculateDistances}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Check Commute'}
        </Button>
        <Button variant="text" color="inherit" onClick={() => setOrigin('')}>
          Clear
        </Button>
      </Grid>

      {/* Display Commute Distances */}
      <Box sx={{ mt: 3 }}>
        {Object.keys(distances).length > 0 &&
          Object.entries(distances).map(([mode, info]) => (
            <Typography variant="body1" sx={{ mt: 2 }} key={mode}>
              {getIconForMode(mode)} {capitalizeFirstLetter(mode)}:{' '}
              {info.distance} ({info.duration})
            </Typography>
          ))}
      </Box>
    </Box>
  );
};

// Helper functions
const getIconForMode = (mode: string) => {
  switch (mode) {
    case 'driving':
      return '🚗';
    case 'bicycling':
      return '🚴';
    case 'walking':
      return '🚶';
    case 'transit':
      return '🚆';
    default:
      return '❓';
  }
};

const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export default DistanceCalculator;
