import React from 'react';
import Button from '@mui/material/Button';
import { useAuthState } from 'react-firebase-hooks/auth';
import { signOut } from 'firebase/auth';
import { auth } from '../../config/firebase-config';
import { useNavigate } from 'react-router-dom';

const AuthControl: React.FC = () => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      console.log('Logged out successfully');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <>
      {user ? (
        <Button onClick={handleSignOut} variant={'contained'}>
          Log Out
        </Button>
      ) : (
        <Button onClick={() => navigate('/login')} variant={'contained'}>
          Sign In | Sign Up
        </Button>
      )}
    </>
  );
};

export default AuthControl;
