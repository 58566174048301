import React from 'react';
import {
  Box,
  Typography,
  Avatar,
  Tooltip,
  IconButton,
  Chip,
} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import { formatChatTimestamp } from '../utils/formatTimestamps';
import { brand } from '../../theme/theme';
import { VerifiedUser } from '@mui/icons-material';
// Define the props for the BidderCard component
interface BidCardProps {
  bidderName: string;
  bidderProfilePic: string;
  verified: boolean;
  lastMessage: string;
  mostRecentBid?: string;
  label?: string;
  seen?: boolean;
  timestamp: string; //ADD seen flag and
  onClick: () => void;
}

const BidCard: React.FC<BidCardProps> = ({
  bidderName,
  bidderProfilePic,
  verified,
  lastMessage,
  mostRecentBid,
  label,
  seen,
  timestamp,
  onClick,
}) => {
  const formattedTime = formatChatTimestamp(timestamp);
  // Determine chip color based on interaction type
  const chipColor =
    label === 'notification'
      ? 'error' // Red for notifications
      : label === 'outgoing_offer'
      ? 'success' // Green for outgoing bids
      : 'primary'; // Blue for Incoming Offers

  return (
    <Box
      onClick={onClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '15px 3px 15px 10px',
        mb: 1,
        borderRadius: '8px',
        border: '1px solid',
        borderColor: 'divider',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        '&:hover': {
          backgroundColor: 'rgba(156, 204, 252, 0.1)',
        },
        overflow: 'auto',
        width: '100%', // Make sure the card takes up the full width of the parent container
      }}
    >
      {/* Blue Dot for Unseen Messages */}
      {!seen && (
        <Box
          sx={{
            width: 5,
            height: 5,
            borderRadius: '50%',
            backgroundColor: '#1F8AFF',
            //m: 1,
            mr: 2,
            p: '7px',
          }}
        />
      )}
      {/* Profile Picture */}
      <Avatar
        src={bidderProfilePic}
        alt="Bidder Profile"
        sx={{ width: 56, height: 56, mr: 2 }}
      />

      {/* Bid and Message Info */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flexGrow: 1,
          overflow: 'hidden',
        }}
      >
        {/* Name and Timestamp Row */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{
              flexGrow: 1,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {bidderName}
            {verified && (
              <Tooltip title="User is verified">
                <VerifiedUser
                  sx={{
                    color: '#1976d2',
                    fontSize: '1.3rem', // Smaller size
                    verticalAlign: 'text-top', // Align with the text
                    ml: 1,
                  }}
                />
              </Tooltip>
            )}
          </Typography>
          {/* Verification */}
          {/* Interaction Type Chip */}
          {label && (
            <Chip
              label={label.replace('_', ' ')} // Format the label (e.g., "outgoing_bid" -> "outgoing bid")
              size="small"
              variant="outlined"
              color={chipColor}
              sx={{
                fontSize: '10px',
                textTransform: 'capitalize',
                borderRadius: '4px',
                marginX: 1, // Add some spacing
              }}
            />
          )}
          {/* Timestamp */}
          <Typography
            variant="caption"
            color="textSecondary"
            sx={{
              flexShrink: 0,
              whiteSpace: 'nowrap',
              ml: 2,
            }}
          >
            {formattedTime.toString()}
          </Typography>
        </Box>

        {/* Last Message */}
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '100%',
            mt: 1,
          }}
        >
          {lastMessage}
        </Typography>

        {/* Most Recent Bid */}
        <Typography
          variant="body2"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '100%',
            color: brand[500],
          }}
        >
          {mostRecentBid}
        </Typography>
      </Box>

      {/* Chat Icon */}
      <Box sx={{ p: 1 }}>
        <Tooltip title="Chat">
          <IconButton size="small">
            <ChatIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default BidCard;
