import React, { useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { createLoginLink } from '../../api/paymentService';

interface EmbeddedStripeOnboardingProps {
  accountId: string | null; // Allow null while accountId is being fetched
  isAccountLoading: boolean; // Prop to control button state
  isStripeAccountComplete: boolean; // Prop to control button state
}

const EmbeddedStripeOnboarding: React.FC<EmbeddedStripeOnboardingProps> = ({
  accountId,
  isAccountLoading,
  isStripeAccountComplete,
}) => {
  const [redirectLoading, setRedirectLoading] = useState<boolean>(false);
  //const [onboardingUrl, setOnboardingUrl] = useState<string | null>(null);
  const handleRedirect = async () => {
    setRedirectLoading(true);
    try {
      if (!accountId) throw new Error('Stripe account ID is not available.');
      const response = await createLoginLink(accountId, isStripeAccountComplete);
      const loginUrl = response.loginUrl;
      //setOnboardingUrl(loginUrl);
      // Open the login URL in a new tab
      window.open(loginUrl, '_blank', 'noopener,noreferrer');
    } catch (error) {
      console.error('Error fetching Stripe onboarding URL:', error);
    } finally {
      setRedirectLoading(false);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleRedirect}
        disabled={isAccountLoading || redirectLoading || !accountId} // Disable if account is still loading or no ID
        sx={{
          backgroundColor: '#635BFF', // Stripe blurple color
          borderRadius: '24px', // Rounded button
          padding: '8px 16px',
          width: 'fit-content', // Prevent full-width button
          textTransform: 'none', // Preserve casing
          display: 'flex', // Flex for alignment
          alignItems: 'center', // Center spinner and text vertically
          gap: '8px', // Add spacing between spinner and text
          '&:hover': {
            backgroundColor: '#4B42D4', // Darker blurple on hover
          },
        }}
      >
        {/* Show spinner if loading */}
        {(isAccountLoading || redirectLoading) && (
          <CircularProgress size={20} color="inherit" />
        )}
        {isStripeAccountComplete
          ? 'Edit Payout Details'
          : 'Set Up Payout Details'}
      </Button>

      {/* <div>
        {onboardingUrl ? (
          <iframe
            src={onboardingUrl}
            title="Stripe Onboarding"
            width="100%"
            height="600px"
            frameBorder="0"
          ></iframe>
        ) : (
          <p>Loading onboarding...</p>
        )}
      </div> */}
      <div style={{ marginTop: '1px', textAlign: 'center' }}>
        <img
          src="/Powered_by_Stripe.png" // Replace with your actual path to the Stripe PNG
          alt="Powered by Stripe"
          style={{ height: '25px', marginLeft: '4px' }}
        />
      </div>
    </>
  );
};

export default EmbeddedStripeOnboarding;
