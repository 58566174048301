// export default Navbar;
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { Badge, MenuItem, Typography } from '@mui/material';
import { Divider } from '@mui/material';
import AuthControl from '../../auth/AuthControl';
import '../../../theme/global.css';
import { getNotificationCount } from '../../../api/notificationService';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../config/firebase-config';
import { io } from 'socket.io-client';
import { useActionMessage } from '../../common/actionMessage/ActionMessage';
import InboxIcon from '@mui/icons-material/Inbox';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HelpIcon from '@mui/icons-material/Help';
import AddCircleIcon from '@mui/icons-material/AddCircle';
interface NavbarProps {
  bannerHeight: number; // Height of the banner to adjust navbar position
}
const socket = io(process.env.REACT_APP_API_BASE_URL as string, {
  // withCredentials: true, // Ensure cookies are sent if needed
});
const Navbar: React.FC<NavbarProps> = ({ bannerHeight }) => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const { showMessage } = useActionMessage();
  // Fetch notification count
  useEffect(() => {
    if (!user) {
      setNotificationCount(0);
      return;
    }

    const getNotifications = async () => {
      try {
        const count = await getNotificationCount();
        if (count > 0) {
          showMessage(
            `You have ${count} unread notifications. View Notifications in Inbox.`,
            'info'
          );
        }
        setNotificationCount(count);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    // Connect to socket and join notification room
    const initializeSocket = () => {
      if (!socket.connected) {
        socket.connect(); // Explicitly reconnect if the socket is not connected
      }

      socket.emit('joinNotifications', user.uid);

      // Handle new notifications
      const handleNewNotification = async () => {
        console.log('notifications:new');
        const count = await getNotificationCount();
        setNotificationCount(count);
        showMessage('New Notification! View Notification in Inbox', 'info');
      };

      // Handle notification read
      const handleNotificationRead = () => {
        console.log('notifications:read');
        setNotificationCount((prev) => Math.max(Number(prev) - 1, 0));
      };

      // Set up socket listeners
      socket.on('notification:new', handleNewNotification);
      socket.on('notification:read', handleNotificationRead);

      // Cleanup
      return () => {
        socket.off('notification:new', handleNewNotification);
        socket.off('notification:read', handleNotificationRead);
      };
    };

    getNotifications();
    const cleanupSocketListeners = initializeSocket();

    return () => {
      // Clean up listeners and socket
      if (cleanupSocketListeners) cleanupSocketListeners();
      socket.disconnect();
    };
  }, [user]);
  // useNotificationSocket(
  //   user?.uid || '',
  //   (newNotification: any) => {
  //     setNotificationCount((prev) => prev + 1); // Increment badge count for new notifications
  //   },
  //   (readNotification: any) => {
  //     setNotificationCount((prev) => Math.max(prev - 1, 0)); // Decrement badge count for read notifications
  //   }
  // );

  return (
    <>
      <AppBar
        position="fixed"
        color="default"
        sx={{
          borderRadius: '999px',
          border: '1px solid',
          borderColor: 'divider',
          top: bannerHeight + 16,
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundColor: 'rgba(255, 255, 255, 0.4)',
          backdropFilter: 'blur(24px)',
          boxShadow: 5,
          width: { xs: '90%', sm: '80%', md: '80%' },
          marginTop: 2,
        }}
      >
        <Container>
          <Toolbar
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'row',
              marginLeft: { xs: 0, md: -2 },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',

                cursor: 'pointer',
                '&:hover': {
                  opacity: 0.8,
                },
              }}
              onClick={() => navigate('/')}
            >
              <Box
                component="img"
                src="https://d1athdg3jj0a20.cloudfront.net/assets/Dublease_Logo.png"
                alt="Dublease Logo"
                sx={{
                  width: { xs: '50px', sm: '70px' }, // Adjust width based on screen size
                  height: 'auto',
                  display: { xs: 'none', sm: 'block' }, // Hide on very small screens
                  mr: 2,
                }}
              />

              {/* Branding with subtitle */}
              <Box display="flex" flexDirection="column">
                <Box
                  component="img"
                  src="https://d1athdg3jj0a20.cloudfront.net/assets/Dublease_Title_Blue.png"
                  alt="Dublease Title"
                  sx={{
                    width: '180px', // Adjust width based on screen size
                    height: 'auto',
                    mt: '3px',
                  }}
                />
                <Box
                  component="img"
                  src="https://d1athdg3jj0a20.cloudfront.net/assets/subleasing_for_college_students.png"
                  alt="subleasing_for_college_students"
                  sx={{
                    width: '250px', // Adjust width based on screen size
                    height: 'auto',
                    mt: '3px',
                  }}
                />
              </Box>
            </Box>

            {/* Menu items for desktop */}
            <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 3 }}>
              <Button
                onClick={() => navigate('/ListYourPlace')}
                className="custom-gray-button"
                sx={{ fontSize: '15px' }}
              >
                List Your Place
              </Button>
              <Button
                onClick={() => navigate('/Profile')}
                className="custom-gray-button"
                sx={{ fontSize: '15px' }}
              >
                Profile
              </Button>
              {notificationCount > 0 ? (
                <Badge
                  badgeContent={notificationCount}
                  color="error"
                  overlap="circular"
                >
                  <Button
                    onClick={() => navigate('/Inbox')}
                    className="custom-gray-button"
                    sx={{ fontSize: '15px', position: 'relative' }}
                  >
                    Inbox
                  </Button>
                </Badge>
              ) : (
                <Button
                  onClick={() => navigate('/Inbox')}
                  className="custom-gray-button"
                  sx={{ fontSize: '15px', position: 'relative' }}
                >
                  Inbox
                </Button>
              )}
              <Button
                component={Link}
                to="/Support"
                className="custom-gray-button"
                sx={{ fontSize: '15px' }}
              >
                Support
              </Button>
              <Divider orientation="vertical" flexItem />
              <AuthControl />
            </Box>

            {/* Mobile View */}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <IconButton color="inherit" onClick={() => setDrawerOpen(true)}>
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                PaperProps={{
                  sx: {
                    width: '75%',
                    maxWidth: '320px',
                    padding: '16px',
                    backgroundColor: 'rgba(255, 255, 255, 0.95)',
                    backdropFilter: 'blur(12px)',
                  },
                }}
              >
                {/* Drawer Header with Logo */}
                <Box display="flex" alignItems="center" mb={2}>
                  <Box
                    component="img"
                    src="https://d1athdg3jj0a20.cloudfront.net/assets/Dublease_Logo_And_Title.png"
                    alt="Dublease Logo"
                    sx={{ width: '170px', height: 'auto', mr: 1 }}
                  />
                </Box>

                {/* Menu Items with Icons */}
                <Divider sx={{ mb: 2 }} />
                <MenuItem
                  onClick={() => {
                    navigate('/ListYourPlace');
                    setDrawerOpen(false);
                  }}
                >
                  <AddCircleIcon sx={{ mr: 1 }} />
                  List Your Place
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate('/Profile');
                    setDrawerOpen(false);
                  }}
                >
                  <AccountCircleIcon sx={{ mr: 1 }} />
                  Profile
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate('/Inbox');
                    setDrawerOpen(false);
                  }}
                >
                  {notificationCount > 0 ? (
                    <Badge
                      badgeContent={notificationCount}
                      color="error"
                      overlap="circular"
                    >
                      <InboxIcon sx={{ mr: 1 }} />
                    </Badge>
                  ) : (
                    <InboxIcon sx={{ mr: 1 }} />
                  )}
                  <Typography
                    variant="inherit"
                    ml={notificationCount > 0 ? 1 : 0}
                  >
                    Inbox
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate('/Support');
                    setDrawerOpen(false);
                  }}
                >
                  <HelpIcon sx={{ mr: 1 }} />
                  Support
                </MenuItem>

                <Divider sx={{ my: 2 }} />
                <MenuItem
                  onClick={() => {
                    navigate('/TermsOfService');
                    setDrawerOpen(false);
                  }}
                >
                  Terms of Service
                </MenuItem>
                <AuthControl />
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default Navbar;
