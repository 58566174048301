import React from 'react';
import {
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface TermsOfServiceProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const TermsOfService: React.FC<TermsOfServiceProps> = ({
  checked,
  onChange,
}) => {
  const navigate = useNavigate();

  return (
    <Grid item xs={12} mt="20px">
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={onChange}
            name="terms_of_service_agreement"
          />
        }
        label={
          <Typography sx={{ fontStyle: 'italic' }}>
            I have read, understand, and agree to the{' '}
            <Link
              href="/TermsOfService"
              onClick={(event) => {
                event.preventDefault();
                navigate('/TermsOfService');
              }}
              style={{ cursor: 'pointer' }}
            >
              Terms of Service
            </Link>
            {' & '}
            <Link
              href="/PrivacyPolicy"
              onClick={(event) => {
                event.preventDefault();
                navigate('/PrivacyPolicy');
              }}
              style={{ cursor: 'pointer' }}
            >
              Privacy Policy
            </Link>
          </Typography>
        }
      />
    </Grid>
  );
};

export default TermsOfService;
