import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface ProtectedRouteProps {
  isAuthenticated: boolean;
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ isAuthenticated, children }) => {
  const location = useLocation();

  if (!isAuthenticated) {
    // Redirect unauthenticated users to login, preserving the path they were trying to access
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  // Render the protected component if the user is authenticated
  return <>{children}</>;
};

export default ProtectedRoute;
