import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Snackbar, Alert, Slide } from '@mui/material';
import {
  CheckCircle,
  Error as ErrorIcon,
  Warning,
  Info,
} from '@mui/icons-material';
import { keyframes } from '@mui/system';

// Function for the slide animation
const SlideTransition = (props: any) => {
  return <Slide {...props} direction="up" />;
};

// Keyframes for light icon bounce animation
const bounce = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
`;

// Context interface
interface ActionMessageContextProps {
  showMessage: (
    message: string,
    severity: 'success' | 'error' | 'warning' | 'info'
  ) => void;
  children?: ReactNode;
}

const ActionMessageContext = createContext<
  ActionMessageContextProps | undefined
>(undefined);

// Custom hook to use the context
export const useActionMessage = () => {
  const context = useContext(ActionMessageContext);
  if (!context) {
    throw new Error(
      'useActionMessage must be used within an ActionMessageProvider'
    );
  }
  return context;
};

// Function to get animated custom icon for each severity
const getIcon = (severity: 'success' | 'error' | 'warning' | 'info') => {
  const iconStyles = {
    animation: `${bounce} 0.6s ease`,
  };
  switch (severity) {
    case 'success':
      return <CheckCircle style={{ ...iconStyles, color: '#4caf50' }} />;
    case 'error':
      return <ErrorIcon style={{ ...iconStyles, color: '#f44336' }} />;
    case 'warning':
      return <Warning style={{ ...iconStyles, color: '#ff9800' }} />;
    case 'info':
      return <Info style={{ ...iconStyles, color: '#2196f3' }} />;
    default:
      return null;
  }
};

// ActionMessageProvider component
export const ActionMessageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<
    'success' | 'error' | 'warning' | 'info'
  >('success');
  const [open, setOpen] = useState(false);

  const showMessage = (
    message: string,
    severity: 'success' | 'error' | 'warning' | 'info'
  ) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Custom styles for each severity with gradient backgrounds
  const getCustomStyles = () => {
    switch (severity) {
      case 'success':
        return {
          background: 'linear-gradient(90deg, #4caf50, #81c784)',
          color: '#fff',
        };
      case 'error':
        return {
          background: 'linear-gradient(90deg, #f44336, #ef5350)',
          color: '#fff',
        };
      case 'warning':
        return {
          background: 'linear-gradient(90deg, #ff9800, #ffb74d)',
          color: '#fff',
        };
      case 'info':
        return {
          background: 'linear-gradient(90deg, #2196f3, #64b5f6)',
          color: '#fff',
        };
      default:
        return {};
    }
  };

  return (
    <ActionMessageContext.Provider value={{ showMessage }}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        TransitionComponent={SlideTransition}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          icon={getIcon(severity)}
          sx={{
            ...getCustomStyles(),
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)', // Softer shadow
            borderRadius: '12px', // Slightly more rounded corners
            padding: '12px 24px', // Less padding for sleek design
            fontWeight: 500,
            textAlign: 'center',
            animation: 'fadeIn 0.5s ease',
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </ActionMessageContext.Provider>
  );
};
