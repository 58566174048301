import { auth } from '../config/firebase-config';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// Fetch the user profile (used: profile.tsx)
export const getCurrentUserProfile = async () => {
  try {
    const user = auth.currentUser;
    // if (!user) throw new Error('User not authenticated');
    if(!user) return {};

    const idToken = await user.getIdToken();
    const response = await fetch(`${API_BASE_URL}/api/user`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch user profile: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};
// Fetch the user profile by a given user ID (used: LeaseDetailsModal -> userDetails.tsx)
export const getUserProfile = async (uid: string) => {
    try {
      const user = auth.currentUser;
      // if (!user) throw new Error('User not authenticated');
      if(!user) return {};
  
      const idToken = await user.getIdToken();
      const response = await fetch(`${API_BASE_URL}/api/user?uid=${uid}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch user profile by ID: ${response.statusText}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching user profile by ID:', error);
      throw error;
    }
  };

// Check if the user's profile is complete (used: PlaceBidTab.tsx)
export const checkProfileCompletion = async (): Promise<boolean> => {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error('User not authenticated');
  
      const idToken = await user.getIdToken();
      const response = await fetch(`${API_BASE_URL}/api/user/checkProfile`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to check profile completion');
      }
  
      const data = await response.json();
      return data.profileComplete;
    } catch (error) {
      console.error('Error checking profile completion:', error);
      return false;
    }
  };

// Save the user profile (used: profile.tsx)
export const updateUserProfile = async (profileData: any) => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('User not authenticated');

    const idToken = await user.getIdToken();
    const response = await fetch(`${API_BASE_URL}/api/user/`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(profileData),
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message || `Failed to save profile: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error saving user profile:', error);
    throw error;
  }
};

export const deleteUserProfile = async () => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('User not authenticated');

    const idToken = await user.getIdToken();
    const response = await fetch(`${API_BASE_URL}/api/user/delete`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to delete user profile');
    }

    return response.json();
  } catch (error) {
    console.error('Error saving user profile:', error);
    throw error;
  }
};

// Upload user profile or student ID picture (used: profile.tsx)
export const uploadUserImage = async (file: File, fileName: string) => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('User not authenticated');

    const idToken = await user.getIdToken();
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);

    const response = await fetch(`${API_BASE_URL}/api/user/upload`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to upload image: ${response.statusText}`);
    }

    const data = await response.json();
    console.log('Image upload response:', data);
    return data.imageUrl;
  } catch (error) {
    console.error('Error uploading user image:', error);
    throw error;
  }
};
