// src/api/interactionService.ts

import { auth } from '../config/firebase-config';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

//  ------------ GET Operations -------------------


// Fetch all owner interactions (bids) for a specific lease and owner
export const getBidsOnLease = async (
    ownerId: string,
    leaseId: string
  ): Promise<any[]> => {
    try {
      const user = auth.currentUser;
      if(!user) return [];
      // if (!user) throw new Error('User not authenticated');
  
      const idToken = await user.getIdToken();
  
      const response = await fetch(
        `${API_BASE_URL}/api/interactions/getBidsOnLease?owner_id=${ownerId}&lease_id=${leaseId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
  
      if (!response.ok) {
        throw new Error(`Failed to fetch bids: ${response.statusText}`);
      }
  
      const data = await response.json();
      return data.ownerInteractions || [];
    } catch (error) {
      console.error('Error fetching owner interactions:', error);
      throw error; // Re-throw the error so it can be handled in the component
    }
};

// Fetch the leases owned by the user (Inbox -> IncomingBids)
export const getOwnerLeases = async (owner_id:string) => {
  try{
    const user = auth.currentUser;
    // if (!user) throw new Error('User not authenticated');
    if (!user) return [];
    const idToken = await user.getIdToken();
    const response = await fetch(`${API_BASE_URL}/api/interactions/getOwnerLeases?owner_id=${owner_id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });
    if (!response.ok) {
      throw new Error(`Failed to fetch leases owned: ${response.statusText}`);
    }
    const data = await response.json();
    return data.leases || [];
  } catch (error) {
    console.error('Error fetching owner interactions:', error);
    throw error;
  }
};
// Function to check if a bid exists for a specific lease from bidder perspective (used: leaseDetailsModal.tsx -> PlaceBidTab.tsx)
export const getBid = async (
  owner_id: string,
  bidder_id: string,
  lease_id: string,
): Promise<any> => { // Use 'any' type here
  try {
    // Get the current user's authentication token
    const user = auth.currentUser;
    // if (!user) throw new Error('User not authenticated');
    if (!user) return {};
    const idToken = await user.getIdToken();

    // Fetch the bid data from the API
    const response = await fetch(
      `${API_BASE_URL}/api/interactions/getBid?owner_id=${owner_id}&bidder_id=${bidder_id}&lease_id=${lease_id}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch bid information');
    }

    const data = await response.json();
    // Return the bid if it exists, otherwise return null
    return data.bidExists ? data.bid : null;
  } catch (error) {
    console.error('Error checking bid existence:', error);
    return null;
  }
};

interface ChatHistoryResponse {
  chat: any[];
  owner_id: string;
  bidder_id: string;
}
// Fetch chat messages for a specific bid (used: Chat.tsx)
export const getChatHistory = async (
    chatId: string ): Promise<ChatHistoryResponse> => {
        try{
            const user = auth.currentUser;
            if (!user) throw new Error('User not authenticated');
            const idToken = await user.getIdToken();
            const response = await fetch(`${API_BASE_URL}/api/interactions/getChatHistory?chat_id=${chatId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`,
                },
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch chat messages: ${response.statusText}`);
            }
            const data = await response.json();

            return {
              chat: data.chat,
              owner_id: data.owner_id,
              bidder_id: data.bidder_id,
            };
        } catch (error) {
            console.error('Error fetching chat messages:', error);
            throw error;
        }
}; 


//  ------------ POST Operations -------------------


// Submit a bid, create a chat, and send pre-made chat message 
// for a lease (used: leaseDetailsModal.tsx -> PlaceBidTab.tsx)
export const saveInitialInteraction = async (
  bidData: {
    owner_id: string;
    bidder_id: string;
    lease_id: string;
    start_date: string;
    end_date: string;
    price: number;
    price_unit: string;
    num_beds_for_bid: number;
    additional_details?: string;
  }
): Promise<any> => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('User not authenticated');

    const idToken = await user.getIdToken();

    const response = await fetch(`${API_BASE_URL}/api/interactions/saveInitialInteraction`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify(bidData),
    });

    if (!response.ok) {
      throw new Error('Failed to submit bid');
    }
    const data = await response.json();
    return data.chat_id;
  } catch (error) {
    console.error('Error submitting bid:', error);
    return false;
  }
};
// Submit a chat message (used: Chat.tsx)
export const sendChatMessage = async (
  messageData: {
    chat_id: string,
    owner_id: string,
    bidder_id: string,
    lease_id: string,
    sender_id: string;
    message: string;
    is_a_bid: boolean;
    timestamp: string;
  }
): Promise<boolean> => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('User not authenticated');

    const idToken = await user.getIdToken();

    const response = await fetch(
      `${API_BASE_URL}/api/interactions/sendChatMessage`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(messageData),
      }
    );

    if (!response.ok) {
      throw new Error('Failed to submit chat message');
    }

    return true;
  } catch (error) {
    console.error('Error submitting chat message:', error);
    return false;
  }
}
export const makeOffer = async (
  bidData: {
    owner_id: string;
    bidder_id: string;
    lease_id: string;
    start_date: string;
    end_date: string;
    price: number;
    price_unit: string;
    num_beds_for_bid: number;
    additional_details?: string;
    is_live: boolean;
  }
): Promise<any> => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('User not authenticated');

    const idToken = await user.getIdToken();

    const response = await fetch(`${API_BASE_URL}/api/interactions/makeOffer`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify(bidData),
    });

    if (!response.ok) {
      throw new Error('Failed to submit offer');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error submitting offer:', error);
    return false;
  }
}


//  ------------ DELETE Operations -------------------


// Delete chat, chat history, bids, and relationship with user (used: Chat.tsx)
export const deleteInteraction = async (
  chat_id: string,
  owner_id: string,
  bidder_id: string,
  lease_id: string
): Promise<boolean> => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('User not authenticated');

    const idToken = await user.getIdToken();

    const response = await fetch(
      `${API_BASE_URL}/api/interactions/deleteInteraction`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({ chat_id, owner_id, bidder_id, lease_id }),
      }
    );

    if (!response.ok) {
      throw new Error('Failed to delete chat');
    }

    return true;
  } catch (error) {
    console.error('Error deleting chat:', error);
    return false;
  }
}
// Rescing offer (used: ReviewForm.tsx)
export const rescindLiveOffers = async (
  chat_id: string,
  owner_id: string,
  bidder_id: string,
  lease_id: string
): Promise<{ success: boolean; message: string}> => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('User not authenticated');

    const idToken = await user.getIdToken();

    const response = await fetch(
      `${API_BASE_URL}/api/interactions/rescindLiveOffers`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({ chat_id, owner_id, bidder_id, lease_id }),
      }
    );

    console.log('rescindLiveOffers response:', response);

    if (!response.ok) {
      throw new Error('Failed to rescind bid');
    }

    const data = await response.json();
    console.log('rescindLiveOffers data:', data);
    return data; 
  } catch (error) {
    console.error('Error rescinding bid:', error);
    return { success: false, message: 'Unable to rescind offer.' };
  }
}