import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Divider,
  Grid,
  CircularProgress,
} from '@mui/material';
import { brand } from '../../theme/theme';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { format } from 'date-fns';
import { calculateMonthlyPrice } from '../utils/calculateStripePayments';

interface AcceptOfferFormProps {
  currentOffer: {
    address: string;
    start_date: string;
    end_date: string;
    price: number;
    price_unit: string;
    num_beds_for_bid: number;
    utilities_included: boolean;
  };
  loading: boolean;
  onProceedToPayment: () => void;
  onCancel: () => void;
}

const AcceptOfferForm: React.FC<AcceptOfferFormProps> = ({
  currentOffer,
  loading,
  onProceedToPayment,
  onCancel,
}) => {
  const [checklist, setChecklist] = useState({
    reviewedOffer: false,
    keyExchange: false,
    contactExchange: false,
  });

  const {
    monthlyPrice,
    fullMonths,
    remainingDays,
    remainingDaysCost,
    totalCost,
  } = calculateMonthlyPrice(
    currentOffer.price,
    currentOffer.price_unit as 'weekly' | 'monthly',
    currentOffer.start_date,
    currentOffer.end_date
  );

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecklist({ ...checklist, [event.target.name]: event.target.checked });
  };

  const isReadyToProceed = Object.values(checklist).every((item) => item);

  // Format dates for better readability
  const formattedStartDate = format(
    new Date(currentOffer.start_date),
    'MMMM d, yyyy'
  );
  const formattedEndDate = format(
    new Date(currentOffer.end_date),
    'MMMM d, yyyy'
  );

  return (
    <Box
      p={1}
      pb={5}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRadius: '10px',
      }}
    >
      {/* Info Section */}
      <Box
        mt={1}
        p={2}
        display="flex"
        alignItems="center"
        sx={{
          border: `1px solid ${brand[500]}`,
          borderRadius: '10px',
          bgcolor: brand[50],
        }}
      >
        <InfoOutlinedIcon sx={{ marginRight: '8px' }} color="primary" />
        <Typography sx={{ textAlign: 'left', fontSize: '15px' }}>
          Dublease offers <strong>secure monthly installments</strong> through
          Stripe, so you <strong>pay as you go</strong> instead of upfront.
          Stripe calls it a <strong>subscription</strong>, but it’s a{' '}
          <strong>fixed installment plan</strong> that ends automatically when
          paid in full.
        </Typography>
      </Box>

      {/* Installment Breakdown */}
      {/* Lease Details Section */}
      <Box
        mt={1}
        p={2}
        sx={{
          border: '1px solid #ccc',
          borderRadius: '10px',
          backgroundColor: '#f9f9f9',
        }}
      >
        <Typography variant="h6" gutterBottom>
          Lease Details
        </Typography>
        <Grid container spacing={1} sx={{ mt: 1 }}>
          <Grid item xs={6}>
            <Typography variant="body2">Address</Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Typography variant="body2">{currentOffer.address}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">Beds</Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Typography variant="body2">
              {currentOffer.num_beds_for_bid} bed(s)
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">Utilities</Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Typography variant="body2">
              {currentOffer.utilities_included ? 'Included' : 'Not Included'}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {/* Installment Breakdown Section */}
      <Box
        mt={2}
        p={2}
        sx={{
          border: '1px solid #ccc',
          borderRadius: '10px',
          backgroundColor: '#f9f9f9',
        }}
      >
        <Typography variant="h6" gutterBottom>
          Installment Breakdown
        </Typography>
        <Typography variant="body1" gutterBottom>
          Sublease from <strong>{formattedStartDate}</strong> to{' '}
          <strong>{formattedEndDate}</strong>.
        </Typography>
        <Grid container spacing={1} sx={{ mt: 1 }}>
          <Grid item xs={6}>
            <Typography variant="body2">Full Months</Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Typography variant="body2">{fullMonths} months</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">Monthly Price</Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Typography variant="body2">${monthlyPrice} / month</Typography>
          </Grid>
          {remainingDays > 0 && (
            <>
              <Grid item xs={6}>
                <Typography variant="body2">Remaining Days</Typography>
              </Grid>
              <Grid item xs={6} textAlign="right">
                <Typography variant="body2">{remainingDays} days</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">Final Partial Payment</Typography>
              </Grid>
              <Grid item xs={6} textAlign="right">
                <Typography variant="body2">
                  ${remainingDaysCost.toFixed(2)}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              Total Amount
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              ${totalCost}
            </Typography>
          </Grid>
        </Grid>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mt: 1, fontStyle: 'italic' }}
        >
          No extra steps needed—just proceed to payment, and Stripe will
          automatically handle monthly charges until the full amount is paid.
        </Typography>
      </Box>
      <div style={{ marginTop: '1px', textAlign: 'center' }}>
        <img
          src="/Powered_by_Stripe.png" // Replace with your actual path to the Stripe PNG
          alt="Powered by Stripe"
          style={{ height: '25px', marginLeft: '4px' }}
        />
      </div>
      {/* 
      <Divider /> */}

      {/* Checklist */}
      <Typography variant="h6" gutterBottom>
        Checklist:
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={checklist.reviewedOffer}
            onChange={handleCheckboxChange}
            name="reviewedOffer"
          />
        }
        label="I have reviewed and agreed to the offer details"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={checklist.keyExchange}
            onChange={handleCheckboxChange}
            name="keyExchange"
          />
        }
        label="I have agreed on the key exchange process with the host"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={checklist.contactExchange}
            onChange={handleCheckboxChange}
            name="contactExchange"
          />
        }
        label="I have exchanged contact numbers with the host"
      />
      <Typography variant="body2" color="text.secondary">
        If you have any questions about the process, feel free to{' '}
        <a href="/Support" style={{ color: '#1976d2' }}>
          contact us
        </a>
        .
      </Typography>

      {/* Action Buttons */}
      <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          disabled={!isReadyToProceed}
          onClick={onProceedToPayment}
        >
          {loading ? <CircularProgress size={24} /> : 'Proceed to Payment'}
        </Button>
        <Button variant="outlined" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default AcceptOfferForm;
