// utils/userService.ts
import { toZonedTime } from 'date-fns-tz';
import { format, isToday, isYesterday, isThisWeek, isThisYear } from 'date-fns';
// DB timestamp format: 2021-10-01T00:00:00.000Z to 2021-10-01
// Used to update start/end date in post-form on edit
export const formatToDateInput = (dateString: string): string => {
  if (!dateString) return '';

  const date = new Date(dateString);
  if (isNaN(date.getTime())) return ''; // Check if the date is valid

  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2); // Add leading zero if necessary
  const day = `0${date.getDate()}`.slice(-2); // Add leading zero if necessary

  return `${year}-${month}-${day}`; // Return in YYYY-MM-DD format
};

// Convert UTC timestamp to the user's local time zone (for chat messages timestamp)
export const formatChatTimestamp = (timestamp: string) => {
  if (isNaN(new Date(timestamp).getTime())) {
    console.error("Invalid timestamp received:", timestamp);
    return "Invalid date";
  }
  // Get the user's local time zone
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // Convert the UTC timestamp to the local time zone
  const utcDate = new Date(timestamp);
  const localDate = toZonedTime(utcDate, timeZone);
  // Format the date based on iMessage-style logic
  if (isToday(localDate)) {
    return format(localDate, "'Today at' p"); // Example: "Today at 11:27 AM"
  } else if (isYesterday(localDate)) {
    return format(localDate, "'Yesterday at' p");
  } else if (isThisWeek(localDate)) {
    return format(localDate, "EEEE 'at' p"); // Example: "Monday at 10:30 AM"
  } else if (isThisYear(localDate)) {
    return format(localDate, "MMM d 'at' p"); // Example: "Oct 9 at 10:30 AM"
  } else {
    return format(localDate, "MMM d, yyyy 'at' p"); // Example: "Aug 20, 2023 at 5:45 PM"
  }
};
// Shorten the date format (e.g., "Oct 1, 2021")
export const formatDate = (dateString: string) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  // Create a Date object from the UTC date string
  const date = new Date(dateString);

  // Convert to UTC and format the date
  return date.toLocaleDateString('en-US', {
    ...options,
    timeZone: 'UTC', // Explicitly set the time zone to UTC
  });
};
