import React from 'react';
import { Grid, TextField, MenuItem, Typography } from '@mui/material';
import { brand } from '../../../theme/theme';

interface AdditionalInformationProps {
  profileData: any;
  handleSwitchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSocialMediaChange: (platform: string, url: string) => void;
  handleAddSocialMedia: () => void;
}

const AdditionalInformation: React.FC<AdditionalInformationProps> = ({
  profileData,
  handleSwitchChange,
  handleChange,
  handleSocialMediaChange,
  handleAddSocialMedia,
}) => (
  <>
    <Typography variant="h6" sx={{ color: brand[500] }} gutterBottom>
      Additional Information
    </Typography>

    <Typography
      variant="subtitle1"
      color="textSecondary"
      gutterBottom
      sx={{ mb: 2, fontStyle: 'italic' }}
    >
      Sharing this info increases trust with other users and boosts engagement
    </Typography>

    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          label="University"
          name="university"
          value={profileData.university}
          onChange={handleChange}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          select
          label="Gender"
          name="gender"
          value={profileData.gender}
          onChange={handleChange}
          fullWidth
          required
        >
          \<MenuItem value="Male">Male</MenuItem>
          <MenuItem value="Female">Female</MenuItem>
          <MenuItem value="Other">Other</MenuItem>
          <MenuItem value="Dont want to share">Don’t want to share</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Major"
          name="major"
          value={profileData.major}
          onChange={handleChange}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          select
          label="Year in School"
          name="year"
          value={profileData.year}
          onChange={handleChange}
          fullWidth
          required
        >
          <MenuItem value="Freshman">Freshman</MenuItem>
          <MenuItem value="Sophomore">Sophomore</MenuItem>
          <MenuItem value="Junior">Junior</MenuItem>
          <MenuItem value="Senior">Senior</MenuItem>
          <MenuItem value="Grad">Grad</MenuItem>
          <MenuItem value="Alum">Alum</MenuItem>
          <MenuItem value="Other">Other</MenuItem>
        </TextField>
      </Grid>
      {/* <Grid item xs={12} sm={6}>
        <TextField
          select
          label="Housing Gender Makeup Preference"
          name="housing_gender_preference"
          value={profileData.housing_gender_preference}
          onChange={handleChange}
          fullWidth
          required
        >
          <MenuItem value="All Male">All Male</MenuItem>
          <MenuItem value="All Female">All Female</MenuItem>
          <MenuItem value="Mixed">Mixed</MenuItem>
          <MenuItem value="No Preference">No Preference</MenuItem>
          <MenuItem value="Other">Other</MenuItem>
        </TextField>
      </Grid> */}
      {profileData.year === 'Other' && (
        <Grid item xs={12} sm={6}>
          <TextField
            label="Please Specify Year In School"
            name="other_year"
            placeholder="e.g. Med Student, Incoming Freshman, etc."
            value={profileData.other_year}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
      )}
      {/* {profileData.housing_gender_preference === 'Other' && (
        <Grid item xs={12} sm={6}>
          <TextField
            label="Please Specify Gender Makeup Preference"
            name="housing_gender_preference_other"
            value={profileData.housing_gender_preference_other}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
      )} */}
    </Grid>
  </>
);

export default AdditionalInformation;
