import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import { brand } from '../../../theme/theme';

const Footer: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          marginTop: 'auto', // Push footer to the bottom when content is sparse
          width: '100%',
          backgroundColor: brand[500],
          padding: 3,
          textAlign: 'center',
          color: 'white',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 4,
            mb: 2,
            flexDirection: { xs: 'column', md: 'row' },
            paddingBottom: { xs: 2, md: 0 },
          }}
        >
          <Link
            onClick={() => navigate('/Support')}
            sx={{
              cursor: 'pointer',
              color: 'white',
              textDecoration: 'none',
              fontSize: '1rem',
              mb: { xs: 1, md: 0 },
            }}
          >
            Support
          </Link>
          <Link
            onClick={() => navigate('/AboutUs')}
            sx={{
              cursor: 'pointer',
              color: 'white',
              textDecoration: 'none',
              fontSize: '1rem',
              mb: { xs: 1, md: 0 },
            }}
          >
            About
          </Link>
          <Link
            onClick={() => navigate('/ListYourPlace')}
            sx={{
              cursor: 'pointer',
              color: 'white',
              textDecoration: 'none',
              fontSize: '1rem',
              lineHeight: '1',
              mb: { xs: 1, md: 0 },
            }}
          >
            List Your Place
          </Link>
          <Link
            onClick={() => navigate('/TermsOfService')} // Open the modal for Terms
            sx={{
              cursor: 'pointer',
              color: 'white',
              textDecoration: 'none',
              fontSize: '1rem',
              lineHeight: '1',
              mb: { xs: 1, md: 0 },
            }}
          >
            Terms of Service
          </Link>
          <Link
            onClick={() => navigate('/PrivacyPolicy')} // Open the modal for Terms
            sx={{
              cursor: 'pointer',
              color: 'white',
              textDecoration: 'none',
              fontSize: '1rem',
              lineHeight: '1',
              mb: { xs: 1, md: 0 },
            }}
          >
            Privacy Policy
          </Link>
        </Box>
        <Box
          sx={{
            position: 'relative',
            display: 'inline-block', // To position the pseudo-element relative to this container
            '&:hover::after': {
              content: '""',
              position: 'absolute',
              top: '-300px', // Position above the text
              left: '50%',
              transform: 'translateX(-50%)',
              width: '500px', // Set a larger fixed width
              aspectRatio: '16/9', // Maintain a 16:9 aspect ratio for scaling
              backgroundImage: 'url("/Austin-Powers.jpg")', // Path to the image
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              borderRadius: '8px', // Optional: rounded corners
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Optional: shadow effect
              zIndex: 2000,
            },
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}
          >
            &copy; {new Date().getFullYear()} Dublease LLC. All rights reserved.
          </Typography>
        </Box>
      </Box>
      {/* Modals */}

      <Outlet />
    </>
  );
};

export default Footer;
