import React, { useState } from 'react';
import {
  IconButton,
  Tooltip,
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  InputAdornment,
} from '@mui/material';
import IosShareIcon from '@mui/icons-material/IosShare';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const ShareButton = ({ leaseId }: { leaseId: string }) => {
  const [open, setOpen] = useState(false);
  const [leaseUrl] = useState(`${window.location.origin}/posts/${leaseId}`);
  const [copied, setCopied] = useState(false);

  const handleShareClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCopied(false); // Reset copied status when modal closes
  };

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(leaseUrl)
      .then(() => {
        setCopied(true); // Set copied state to true
      })
      .catch((error) => console.error('Error copying the lease URL', error));
  };

  return (
    <>
      {/* Share Icon Button */}
      <Tooltip title="Share Lease">
        <IconButton onClick={handleShareClick} sx={{ zIndex: 1 }}>
          <IosShareIcon />
        </IconButton>
      </Tooltip>

      {/* Modal for sharing lease URL */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="share-lease-modal"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            minWidth: 420,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            Share this Dublease
          </Typography>

          {/* Text field with lease URL and copy button */}
          <TextField
            value={leaseUrl}
            variant="outlined"
            fullWidth
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    onClick={handleCopyClick}
                    variant="text"
                    sx={{
                      minWidth: 40,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <ContentCopyIcon />
                  </Button>
                </InputAdornment>
              ),
            }}
            sx={{
              backgroundColor: 'grey.100', // Grey out the background
              borderRadius: 1,
              marginBottom: 2,
            }}
          />
          {copied && (
            <Typography variant="caption" sx={{ marginTop: 1, color: 'green' }}>
              Link copied to clipboard!
            </Typography>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default ShareButton;
