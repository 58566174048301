import React, { useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import GoogleIcon from '@mui/icons-material/Google';
import { useSignInWithGoogle } from 'react-firebase-hooks/auth';
import { auth, db } from '../../config/firebase-config';
import { useLocation, useNavigate } from 'react-router-dom';
import { setPersistence, browserSessionPersistence } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { sendSignInUpEmail } from '../../api/notificationService';

const Login = () => {
  const [signInWithGoogle, , loading, error] = useSignInWithGoogle(auth);
  const navigate = useNavigate();
  const location = useLocation();
  // Determine where to redirect after login
  const redirectPath = location.state?.from?.pathname || '/';
  // Set Firebase persistence to session-based (no cookies for persistence)
  useEffect(() => {
    setPersistence(auth, browserSessionPersistence).catch((err) => {
      console.error('Error setting session persistence:', err);
    });
  }, []);

  // Save to firebase, api call to send email to founders
  const handleSignIn = async () => {
    try {
      const userCredential = await signInWithGoogle();
      const user = userCredential?.user || null;
      if (user) {
        // Redirect the user immediately
        navigate(redirectPath);

        // Run background tasks asynchronously
        (async () => {
          try {
            const userRef = doc(db, 'users', user.uid);
            const userDoc = await getDoc(userRef);
            const isNewUser = !userDoc.exists();
            const currentTime = new Date().toLocaleString();

            const userData = isNewUser
              ? { createdAt: currentTime }
              : userDoc.data();

            // Send sign-in/up email
            await sendSignInUpEmail(
              user.uid,
              user.email || '',
              user.displayName || '',
              user.photoURL || '',
              userData.createdAt || '',
              currentTime,
              isNewUser
            );

            // Save user data to Firestore if new user
            if (isNewUser) {
              await setDoc(userRef, {
                uid: user.uid,
                email: user.email,
                displayName: user.displayName,
                photoURL: user.photoURL,
                createdAt: new Date().toLocaleString(),
              });
            }
          } catch (err) {
            console.error('Error in background tasks:', err);
          }
        })();
      }
    } catch (err) {
      console.error('Error during sign-in:', err);
    }
  };

  const handleClose = () => {
    navigate('/'); // Close modal and navigate to intended route or fallback
  };

  return (
    <Modal open onClose={handleClose} closeAfterTransition>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          maxWidth: '90%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 3,
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          alignItems: 'center',
          animation: 'fade-in 0.3s ease',
        }}
      >
        {/* Logo */}
        <Box
          component="img"
          //src="/Dublease_Mascot.png"
          src="https://d1athdg3jj0a20.cloudfront.net/assets/Dublease_Mascot.png"
          alt="Dublease Logo"
          sx={{
            width: 100,
            height: 'auto',
            mb: 2,
          }}
        />

        {/* Title */}
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Welcome to Dublease
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Sign in to explore subleasing options!
        </Typography>

        {/* Divider */}
        <Divider sx={{ width: '100%' }} />

        {/* Google Sign-In Button */}
        {loading ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            onClick={handleSignIn}
            startIcon={<GoogleIcon />}
            sx={{
              backgroundColor: '#4285F4',
              color: 'white',
              textTransform: 'none',
              fontWeight: 'bold',
              '&:hover': {
                backgroundColor: '#357ae8',
              },
              width: '100%',
              py: 1.5,
            }}
          >
            Sign in with Google
          </Button>
        )}

        {/* Error Handling */}
        {error && (
          <Typography color="error" mt={2} fontSize="0.9rem">
            {error.message || 'An error occurred. Please try again.'}
          </Typography>
        )}

        {/* Close/Cancel Button */}
        <Button
          onClick={() => navigate('/')}
          sx={{
            mt: 2,
            textTransform: 'none',
            fontWeight: 'medium',
            color: 'text.secondary',
          }}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

export default Login;
