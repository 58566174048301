export const calculateMonthlyPrice = (
  price: number,
  priceUnit: 'weekly' | 'monthly',
  startDate: string,
  endDate: string
) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Calculate total number of days between start and end date
  const totalDays = Math.ceil(
    (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)
  );

  const parsedPrice = parseFloat(price.toString());
  if (isNaN(parsedPrice)) {
    throw new Error('Invalid price value');
  }
  let monthlyPrice = parsedPrice;
  if (priceUnit === 'weekly') {
    // Convert weekly price to monthly price by calculating the lease's exact weeks & days
    monthlyPrice = (price * totalDays) / 30.437; // Average days per month over a year
  }

  // Calculate full months and remaining days
  const fullMonths = Math.floor(totalDays / 30.437);
  const remainingDays = Math.round(totalDays % 30.437); // Remaining days in the lease

  // Calculate the prorated cost for remaining days
  const dailyRate = monthlyPrice / 30.437;
  const remainingDaysCost = remainingDays * dailyRate;

  // Total cost calculation
  const totalCost = fullMonths * monthlyPrice + remainingDaysCost;

  return {
    monthlyPrice: parseFloat(monthlyPrice.toFixed(2)), // Round to 2 decimal places
    fullMonths,
    remainingDays,
    remainingDaysCost: parseFloat(remainingDaysCost.toFixed(2)),
    totalCost: parseFloat(totalCost.toFixed(2)),
  };
};
