import React from 'react';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { brand } from '../../../../theme/theme';
import { Link, useNavigate } from 'react-router-dom';

const privacyData = [
  {
    title: 'Information We Collect',
    sections: [
      {
        bullet: 'Profile Information:',
        text: 'When you create a profile on Dublease, we collect the following required personal information:',
        subItems: [
          'First Name',
          'Last Name',
          'Email Address',
          'Phone Number',
          'University',
          'Student ID Number',
          'Profile Picture',
          'Student ID Picture',
          'Gender',
          'Major',
          'Year in School',
          'Gender Makeup Preference for Housing',
        ],
      },
      {
        bullet: 'Listing Information:',
        text: 'When you list a property on Dublease, we collect details about your sublease, including:',
        subItems: [
          'Property Address',
          'Start and End Date of Availability',
          'Proposed Price',
          'Number of Rooms for Lease',
          'Total Number of Rooms in the Unit',
          'Number of Bathrooms',
          'Roommate Situation (whether you have roommates, if they’re aware of the sublease, and if they’ll be present)',
          'Gender Composition of the House',
          'Available Amenities',
          'Property Description',
          'Photos of the Property',
        ],
      },
      {
        bullet: 'Offers Information:',
        text: 'When you make an offer on a listing, we collect details related to your offer, including:',
        subItems: [
          'Requested Dates for the Sublease',
          'Preferred Price',
          'Number of Beds Requested',
          'Any Additional Details of the Offer (such as specific preferences or requests)',
        ],
      },
      {
        bullet: 'Communication Data:',
        text: 'We monitor and access messages exchanged in the chat between users, including offers, inquiries, and other communications. This helps ensure smooth communication, address misuse, and improve user experience.',
      },
      {
        bullet: 'Usage Data:',
        text: 'We collect telemetry data, including but not limited to:',
        subItems: [
          'IP Address',
          'Browser Type',
          'Pages Visited',
          'Time Spent on the Platform',
          'Interactions with Listings, Messages, and Offers',
          'Heat Maps',
        ],
      },
    ],
  },
  {
    title: 'How We Use Your Information',
    sections: [
      {
        bullet: 'To Provide and Maintain the Service:',
        subItems: [
          'Facilitate connections between hosts and guests to discuss sublease opportunities.',
          'Manage your account and verify your identity.',
        ],
      },
      {
        bullet: 'To Improve the Service:',
        subItems: [
          'Analyze usage patterns to enhance the user experience.',
          'Monitor and report on service performance and user interactions.',
        ],
      },
      {
        bullet: 'To Communicate with You:',
        subItems: [
          'Send notifications about your account, listings, or offers activity.',
          'Contact you via text, email, or phone for essential service-related reasons.',
          'Notify you of service updates or respond to support inquiries.',
          'Send in-app notifications or push notifications if you have consented to receive them.',
        ],
      },
    ],
  },
  {
    title: 'Sharing Your Information',
    sections: [
      {
        bullet: 'With Service Providers:',
        text: 'We may share information with trusted third-party service providers to support our platform, such as:',
        subItems: [
          'Cloud storage and hosting providers.',
          'Communication services to send notifications and updates.',
        ],
      },
      {
        bullet: 'For Legal Reasons:',
        text: 'We may disclose your information if required by law, or if disclosure is necessary to protect our legal rights or comply with legal processes.',
      },
    ],
  },
  {
    title: 'Data Security',
    content: [
      'We use industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no system is completely secure, and we cannot guarantee absolute protection.',
    ],
  },
  {
    title: 'User Rights',
    sections: [
      {
        bullet: 'You have the right to:',
        subItems: [
          'Access: Request a copy of the personal information we hold about you.',
          'Correction: Request that we update or correct any inaccurate information.',
          'Deletion: Request the deletion of your personal data.',
        ],
      },
      {
        text: 'To exercise any of these rights, please contact us through the "Support" page on the Dublease platform.',
      },
    ],
  },
  {
    title: 'Notifications and Communication Preferences',
    content: [
      'We provide notifications for platform activity, including:',
      'In-App Notifications: Alerts for new messages, offers, or activity related to your listings.',
      'Out-of-App Notifications: Email and text notifications, depending on your selected preferences.',
      'Essential Communications: We may contact you via email, text, or phone if it is necessary for essential service reasons, such as security issues or critical updates.',
      'You can adjust your notification preferences in your profile at any time.',
    ],
  },
  {
    title: 'Usage Tracking and Analytics',
    content: [
      'Dublease tracks user interactions and usage metrics, including page visits, time spent on the platform, and engagement with listings and messages. This data helps us improve platform performance, address technical issues, and better understand user needs.',
    ],
  },
  {
    title: 'Data Retention',
    content: [
      'We retain your personal information for as long as necessary to fulfill the purposes described in this policy. If you request account deletion, we will delete or anonymize your data unless we are required to retain it for legal or regulatory reasons.',
    ],
  },
  {
    title: 'Changes to This Privacy Policy',
    content: [
      'We may update this Privacy Policy from time to time. Changes will be communicated in accordance with our Terms of Service and posted on the platform. Continued use of Dublease after changes are made constitutes acceptance of the updated policy.',
    ],
  },
  {
    title: 'Contact Us',
    content: [
      'If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us via the "Support" page on the Dublease platform.',
    ],
  },
  {
    title: 'Acceptance of Terms',
    content: [
      'By clicking "I Agree," you confirm that you have read, understood, and accepted the Dublease Privacy Policy. This acknowledgment is required to create listings, place offers, or engage in subleasing transactions on the platform.',
    ],
  },
];

const PrivacyPolicy: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Modal open onClose={() => navigate(-1)}>
      <Box
        sx={{
          borderRadius: '10px',
          border: '1px solid',
          borderColor: 'divider',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: '80%', md: '70%', lg: '50%' },
          height: '90vh',
          maxHeight: '90vh',
          overflowY: 'auto',
          bgcolor: 'background.paper',
          boxShadow: `0 0 100px 10px rgb(156, 204, 252)`,
          p: 4,
          outline: 0,
        }}
      >
        {/* Logo & Close */}
        <Box
          display="flex"
          flexDirection="row"
          sx={{ mb: 2, justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Box
            component="img"
            src="/Dublease_Logo_And_Title.png"
            alt="Dublease Logo"
            sx={{
              width: '200px',
              height: 'auto',
            }}
          />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton sx={{ zIndex: 1 }} onClick={() => navigate(-1)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        <Typography
          variant="h4"
          sx={{
            fontWeight: 'bold',
            color: brand[500],
            mb: 3,
            textAlign: 'center',
          }}
        >
          Privacy Policy
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            textAlign: 'left',
            fontStyle: 'italic',
            mb: 2,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ fontWeight: 'bold', mr: 1 }}>
            Last Updated:
          </Typography>
          <Typography>12/21/24</Typography>
        </Typography>
        {/* Content */}
        {privacyData.map((section, idx) => (
          <Box key={idx} sx={{ mb: 4 }}>
            <Typography
              variant="h6"
              sx={{ color: brand[400], fontWeight: 'bold', mb: 1 }}
            >
              {section.title}
            </Typography>
            {section.content &&
              section.content.map((paragraph, i) => (
                <Typography key={i} variant="body1" paragraph>
                  {paragraph}
                </Typography>
              ))}
            {section.sections &&
              section.sections.map((item, i) => (
                <Box key={i} sx={{ mb: 2 }}>
                  {item.bullet && (
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {item.bullet}
                    </Typography>
                  )}
                  <Typography variant="body1" sx={{ ml: 2 }}>
                    {item.text}
                  </Typography>
                  {item.subItems && (
                    <ul style={{ marginLeft: '20px' }}>
                      {item.subItems.map((subItem, j) => (
                        <li key={j}>
                          <Typography variant="body1">{subItem}</Typography>
                        </li>
                      ))}
                    </ul>
                  )}
                </Box>
              ))}
          </Box>
        ))}

        {/* Footer */}
        <Typography variant="body1" paragraph>
          For more information, visit our{' '}
          <Link
            to="/TermsOfService"
            style={{ color: brand[500], textDecoration: 'none' }}
          >
            Terms of Service
          </Link>
        </Typography>
      </Box>
    </Modal>
  );
};

export default PrivacyPolicy;
