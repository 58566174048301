// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
// import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  // apiKey: 'AIzaSyCsyt1m5kdU78eWOACSzDfVcPiiH8-BNYA',
  // authDomain: 'dublease-2fcd0.firebaseapp.com',
  // projectId: 'dublease-2fcd0',
  // storageBucket: 'dublease-2fcd0.appspot.com',
  // messagingSenderId: '228724465057',
  // appId: '1:228724465057:web:57989b3af00e49dcab3fe0',
  // measurementId: 'G-8W0EH0P6HE',
  apiKey: 'AIzaSyDgsPNyykktKZyrFpy3KCYwCtDl3HBab5c',
  authDomain: 'dublease-433822.firebaseapp.com',
  projectId: 'dublease-433822',
  storageBucket: 'dublease-433822.firebasestorage.app',
  messagingSenderId: '169912367133',
  appId: '1:169912367133:web:cb19f9578b282a4db46ef4',
  measurementId: 'G-8F55MLGJZT',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
// const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
