import React from 'react';
import {
  Modal,
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { brand } from '../../../../theme/theme'; // Import your theme
import { Link, useNavigate } from 'react-router-dom';

const termsData = [
  {
    title: '1. Description of Service',
    content: [
      'Dublease provides a platform designed exclusively for college students using a valid school email (e.g., umich.edu) to connect for subleasing opportunities.',
      'We facilitate connections between hosts (those looking to sublease their property) and guests (those seeking a sublease). Dublease is not responsible for property management, lease agreements between users and their property owners, or any guarantees related to subleasing transactions.',
    ],
  },
  {
    title: '2. User Accounts',
    content: [
      {
        bullet: 'Eligibility:',
        text: 'Only current or former college students with valid school emails are eligible to use Dublease. For added safety, Dublease hides exact addresses from users unless they sign in through a valid school email.',
      },
      {
        bullet: 'Profile Creation:',
        text: 'You must create a profile to access certain features of the Service. You are responsible for maintaining the confidentiality of your account information and all activities under your account.',
      },
      {
        bullet: 'Profile Verification:',
        text: 'Users can optionally verify their profile by submitting a profile picture and a student ID photo. Verified profiles receive a blue checkmark badge to increase trust and engagement. Only the profile picture is visible to other users.',
      },
    ],
  },
  {
    title: '3. Listings and Legal Responsibility',
    content: [
      {
        bullet: 'Hosts and Guests:',
        text: 'A host is a sublessor listing a property, while a guest is a subletter seeking to lease it.',
      },
      {
        bullet: 'Legal Compliance:',
        text: 'By creating a listing or engaging in a sublease, you confirm that you are legally allowed to sublease your property and have obtained necessary permissions from your property owner. Dublease is not liable for any legal issues arising between you and your property owner.',
      },
      {
        bullet: 'Contractual Relationship:',
        text: 'Dublease facilitates connections but does not create or enforce any agreements. Sublease terms, payment, and other arrangements are handled directly between the host and guest.',
      },
      {
        bullet: 'Custom Agreements:',
        text: 'Hosts may provide custom terms or contracts, but these terms must be disclosed and handled separately before booking. Dublease is not involved in, nor liable for, any terms, conditions, or disputes arising from custom agreements between hosts and guests.',
      },
    ],
  },
  {
    title: '4. Transactions, Payments, and Fees',
    content: [
      {
        bullet: 'Payments:',
        text: 'At this stage, Dublease does not handle payments. All payments must be arranged directly between the host and guest off-platform. Dublease is not involved in payment processing, does not guarantee payments, and does not mediate payment-related disputes. Dublease charges no fees at this time.',
      },
      {
        bullet: 'Offers:',
        text: 'When a guest places an offer on a listing, it opens a chat between the guest and host. Users are encouraged to discuss all sublease terms, including move-in dates, rental price, and other relevant details. Once both parties agree on the terms, they must coordinate and manage payment arrangements directly.',
      },
    ],
  },
  {
    title: '5. Cancellations, Terminations, and Refunds',
    content: [
      {
        bullet: 'No Dublease Cancellation Policy:',
        text: 'Since Dublease does not handle payments, we do not have a formal cancellation policy. Any cancellations, refunds, or changes to the agreement must be handled directly between the host and guest. Users are encouraged to communicate and document all changes clearly to avoid misunderstandings.',
      },
      {
        bullet: 'User Responsibility:',
        text: 'Both hosts and guests are responsible for setting clear terms during the chat and ensuring mutual understanding of any refund, cancellation, or rescheduling policies they agree upon.',
      },
    ],
  },
  {
    title: '6. Risk, Liability, and Dispute Resolution',
    content: [
      'You assume all risks related to your sublease transaction, including fraud, theft, property damage, and personal safety.',
      'Dublease does not conduct background checks, credit checks, or consumer reports on users.',
      'Hosts and guests are encouraged to discuss key details like key exchanges, entry and exit times, and payment methods to ensure a smooth transition. Dublease does not enforce these arrangements.',
    ],
  },
  {
    title: '7. Messaging and Communication',
    content: [
      {
        bullet: 'Chat System:',
        text: "Users can message each other via Dublease's in-app chat to discuss sublease details. Dublease does not moderate or intervene in these conversations.",
      },
      {
        bullet: 'Notifications:',
        text: 'Dublease provides in-app notifications for account activity. Users may also opt-in for text and/or email notifications.',
      },
      {
        bullet: 'Essential Contact:',
        text: 'By using Dublease, you authorize us to contact you via text, call, or email for essential service reasons.',
      },
    ],
  },
  {
    title: '8. Usage Tracking',
    content: [
      'Dublease reserves the right to track usage telemetry, including metrics like number of users, interactions, listings, transactions, and messages, as well as usage heatmaps, to better understand and improve the platform.',
    ],
  },
  {
    title: '9. Listing Expiration',
    content: [
      {
        bullet: 'Start Date Adjustments:',
        text: 'If the listed start date has passed, it will be automatically updated to today’s date, while the end date and rate remain unchanged.',
      },
      {
        bullet: 'End Date Notifications:',
        text: 'One week before the listed end date, the host will be notified. If no action is taken within a week, the listing will be deactivated from public view.',
      },
    ],
  },
  {
    title: '10. User Conduct',
    content: [
      {
        bullet: 'Honest Representation:',
        text: 'When listing a property, users promise that all information provided is accurate and truthful.',
      },
      {
        bullet: 'Harassment Policy:',
        text: 'Dublease enforces a zero-tolerance policy for harassment. Violations result in account removal.',
      },
    ],
  },
  {
    title: '11. Illegal Use and False Listings',
    content: [
      'By using Dublease, you confirm that all listings and accounts are legitimate, and you have the authority to post them. Fraudulent activities may result in legal consequences.',
    ],
  },
  {
    title: '12. Privacy Policy',
    content: [
      'To learn more about how we collect, use, and protect your personal information, please review our Privacy Policy.',
    ],
  },
  {
    title: '13. Changes to the Terms of Service or Privacy Policy',
    content: [
      'We reserve the right to modify or update these Terms of Service or Privacy Policy at any time. Users will be notified via in-app messages or emails, depending on their preferences. Continued use of Dublease after changes are made constitutes acceptance of the updated policies.',
    ],
  },
  {
    title: '14. Contact Us',
    content: [
      'If you have any questions or concerns regarding these Terms, please contact us through the “Support” page on the Dublease platform.',
    ],
  },
  {
    title: 'Acceptance of Terms',
    content: [
      'By clicking "I Agree," you confirm that you have read, understood, and accepted the Dublease Terms of Service. This acknowledgment is required to create listings, place offers, or engage in subleasing transactions on the platform.',
    ],
  },
];

const TermsOfService = () => {
  const navigate = useNavigate();

  return (
    <Modal open onClose={() => navigate(-1)}>
      <Box
        sx={{
          borderRadius: '10px',
          border: '1px solid',
          borderColor: 'divider',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '95%', sm: '80%', md: '70%', lg: '50%' }, // Responsive width
          height: '90vh',
          maxHeight: '90vh',
          overflowY: 'auto',
          bgcolor: 'background.paper',
          boxShadow: `0 0 100px 10px rgb(156, 204, 252)`,
          p: 4,
          outline: 0,
        }}
      >
        {/* Logo & Close */}
        <Box
          display="flex"
          flexDirection="row"
          sx={{ mb: 2, justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Box
            component="img"
            src="/Dublease_Logo_And_Title.png"
            alt="Dublease Logo"
            sx={{
              width: '200px',
              height: 'auto',
            }}
          />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton sx={{ zIndex: 1 }} onClick={() => navigate(-1)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        {/* Scrollable Content */}
        <Box>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              color: brand[500],
              mb: 3,
              textAlign: 'center',
            }}
          >
            Terms of Service
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              textAlign: 'left',
              fontStyle: 'italic',
              mb: 2,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ fontWeight: 'bold', mr: 1 }}>
              Last Updated:
            </Typography>
            <Typography>12/21/24</Typography>
          </Typography>
          {termsData.map((section, idx) => (
            <Box key={idx} sx={{ mb: 3 }}>
              {/* Section Title */}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 'bold',
                  color: brand[400],
                  mb: 1,
                }}
              >
                {section.title}
              </Typography>

              {/* Section Content */}
              {section.content.map((item, index) => {
                if (typeof item === 'string') {
                  return (
                    <Typography key={index} variant="body1" paragraph>
                      {item}
                    </Typography>
                  );
                } else {
                  return (
                    <List
                      key={index}
                      sx={{
                        listStyleType: 'disc',
                        pl: 4,
                        color: 'text.primary',
                      }}
                    >
                      <ListItem sx={{ display: 'list-item', mb: 1 }}>
                        <Typography
                          component="span"
                          sx={{ fontWeight: 'bold' }}
                        >
                          {item.bullet}{' '}
                        </Typography>
                        <Typography component="span">{item.text}</Typography>
                      </ListItem>
                    </List>
                  );
                }
              })}
            </Box>
          ))}
          {/* Footer */}
          <Typography variant="body1" paragraph>
            For more information, visit our{' '}
            <Link
              to="/PrivacyPolicy"
              style={{ color: brand[500], textDecoration: 'none' }}
            >
              Privacy Policy
            </Link>
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default TermsOfService;
