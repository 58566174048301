import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { motion } from 'framer-motion';

interface SplashScreenProps {
  onComplete: () => void;
}

const SplashScreen: React.FC<SplashScreenProps> = ({ onComplete }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onComplete();
    }, 1500); // 3 seconds delay

    return () => clearTimeout(timer);
  }, [onComplete]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <motion.div
        initial={{ scale: 0.5, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.5, opacity: 0 }}
        transition={{
          duration: 1,
          ease: 'easeInOut',
        }}
        style={{ textAlign: 'center' }}
      >
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1, delay: 0.3 }}
        >
          <Box
            component="img"
            src="https://d1athdg3jj0a20.cloudfront.net/assets/Dublease_Full_Logo.png"
            alt="Dublease Logo"
            sx={{
              width: { xs: '350px', sm: '700px', md: '1000px' }, // Adjust width for responsiveness
              height: 'auto',
            }}
          />
        </motion.div>
      </motion.div>
    </Box>
  );
};

export default SplashScreen;

// import React, { useEffect } from 'react';
// import { Box, Typography, useMediaQuery } from '@mui/material';
// import { motion } from 'framer-motion';
// import theme from '../../theme/theme';

// interface SplashScreenProps {
//   onComplete: () => void;
// }

// const SplashScreen: React.FC<SplashScreenProps> = ({ onComplete }) => {
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       onComplete();
//     }, 3000); // 3 seconds delay

//     return () => clearTimeout(timer);
//   }, [onComplete]);

//   return (
//     <Box
//       display="flex"
//       justifyContent="center"
//       alignItems="center"
//       minHeight="100vh"
//       bgcolor={theme.palette.background.default}
//     >
//       <motion.div
//         initial={{ scale: 0.5, opacity: 0 }}
//         animate={{ scale: 1, opacity: 1 }}
//         exit={{ scale: 0.5, opacity: 0 }}
//         transition={{
//           duration: 1.5,
//           ease: 'easeInOut',
//         }}
//         style={{ textAlign: 'center' }}
//       >
//         <motion.div
//           initial={{ y: 20, opacity: 0 }}
//           animate={{ y: 0, opacity: 1 }}
//           transition={{ duration: 1, delay: 0.3 }}
//         >
//           <Box
//             component="img"
//             src="/Dublease_Full_Logo.png"
//             alt="Dublease Logo"
//             sx={{
//               width: { xs: '300px', sm: '500px', md: '700px' }, // Adjust width for responsiveness
//               height: 'auto',
//             }}
//           />
//         </motion.div>
//         <motion.div
//           initial={{ opacity: 0 }}
//           animate={{ opacity: 1 }}
//           transition={{ duration: 1.5, delay: 1 }}
//         >
//           <Typography
//             variant="h5"
//             sx={{
//               mt: 2,
//               fontWeight: 600,
//               color: theme.palette.text.primary,
//             }}
//           >
//             Welcome to Dublease
//           </Typography>
//         </motion.div>
//       </motion.div>
//     </Box>
//   );
// };

// export default SplashScreen;
