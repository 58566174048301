import { useNavigate } from 'react-router-dom';

export const useSafeNavigate = () => {
  const navigate = useNavigate();

  const safeNavigate = (fallback = '/') => {
    const { pathname } = window.location;

    if (pathname === '/') {
      // If we're at the root, navigate to fallback
      navigate(fallback);
    } else {
      // Otherwise, go back one route
      navigate(-1);
    }
  };

  return safeNavigate;
};